/**
 * WordPress Dependencies
 */
import { __ } from "@wordpress/i18n";
import { shuffle, gallery } from "@wordpress/icons";
import { addFilter } from "@wordpress/hooks";
import { useEffect } from "@wordpress/element";
import { assign, isEmpty, isEqual, has } from "lodash";
import { createHigherOrderComponent } from "@wordpress/compose";
import {
	ToolbarGroup,
	ToolbarButton,
	PanelBody,
	__experimentalUnitControl as UnitControl,
} from "@wordpress/components";
import {
	BlockControls,
	InspectorControls,
	__experimentalGetGapCSSValue as getGapCSSValue,
} from "@wordpress/block-editor";
/**
 * Custom Imports
 */
import "./editor.scss";
import "./style.scss";
import { shuffleInnerBlocks } from "./utils";

/** @typedef {import('@wordpress/blocks').WPBlockVariation} WPBlockVariation */

/**
 * Template option choices for predefined columns layouts.
 *
 * @type {WPBlockVariation[]}
 */

wp.blocks.registerBlockVariation("core/gallery", {
	name: "core/gallery",
	title: __("Gallery", "ghub-justified-ext"),
	description: __(
		"Display multiple images in a rich gallery.",
		"ghub-justified-ext"
	),
	icon: gallery,
	isDefault: true,
	category: "media",
	attributes: {
		ghubVariation: "default",
		className: "",
	},
	scope: ["inserter", "transform"],
	isActive: (blockAttributes, variationAttributes) =>
		isEqual(blockAttributes.ghubVariation, variationAttributes.ghubVariation),
});
wp.blocks.registerBlockVariation("core/gallery", {
	name: "ghub-justified-gallery",
	title: __("Justified Gallery", "ghub-justified-ext"),
	description: __(
		"Display multiple images in a justified gallery.",
		"ghub-justified-ext"
	),
	category: "ghub-products",
	keywords: ["justified", "gallery"],
	icon: (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ fill: "none" }}
		>
			<path
				d="M11 21H3V3H11V21ZM9 19V5H5V19H9ZM13 11V3H21V11H13ZM15 9H19V5H15V9ZM13 21V13H21V21H13ZM15 19H19V15H15V19Z"
				fill="currentColor"
			/>
		</svg>
	),
	attributes: {
		ghubVariation: "justified-gallery",
		className: "is-style-justified",
		ghubHeight: "200px",
		ghubVerticalGap: "20px",
		ghubHorizontalGap: "20px",
	},
	scope: ["transform", "inserter"],
	isActive: (blockAttributes, variationAttributes) =>
		isEqual(blockAttributes.ghubVariation, variationAttributes.ghubVariation),
});

/**
 * Add new attributes to the block
 */
const addAttributes = (props) => {
	const { attributes } = props;
	if (isEmpty(attributes) || props.name !== "core/gallery") {
		return props;
	}
	assign(
		props.attributes,
		{
			...attributes,
			ghubVariation: {
				type: "string",
				default: "default",
			},
			ghubHeight: {
				type: "string",
			},
			ghubVerticalGap: {
				type: "string",
				default: "",
			},
			ghubHorizontalGap: {
				type: "string",
				default: "",
			},
		},
		{}
	);

	return props;
};

const withAdvanceControls = createHigherOrderComponent((BlockEdit) => {
	return (props) => {
		const { attributes, setAttributes, name } = props;

		if (
			name !== "core/gallery" ||
			(name === "core/gallery" &&
				attributes?.ghubVariation !== "justified-gallery")
		) {
			return <BlockEdit {...props} />;
		}
		if (
			has(attributes, "style") &&
			has(attributes?.style, "spacing") &&
			has(attributes?.style?.spacing, "blockGap")
		) {
			useEffect(() => {
				setAttributes({
					ghubVerticalGap: getGapCSSValue(
						attributes?.style?.spacing?.blockGap?.top
					),
					ghubHorizontalGap: getGapCSSValue(
						attributes?.style?.spacing?.blockGap?.left
					),
				});
			}, [attributes?.style?.spacing?.blockGap]);
		}

		return (
			<>
				<BlockEdit {...props} />

				<BlockControls>
					<ToolbarGroup>
						<ToolbarButton
							icon={shuffle}
							label={__("Shuffle Images", "ghub-justified-ext")}
							onClick={() => {
								shuffleInnerBlocks(props.clientId);
							}}
						/>
					</ToolbarGroup>
					<InspectorControls>
						<PanelBody>
							<UnitControl
								label={__("Image Height", "ghub-justified-ext")}
								onChange={(newValue) => setAttributes({ ghubHeight: newValue })}
								value={attributes?.ghubHeight}
							/>
						</PanelBody>
					</InspectorControls>
				</BlockControls>
			</>
		);
	};
}, "withAdvanceControls");

const withBlockWrapper = createHigherOrderComponent((BlockListBlock) => {
	return (props) => {
		const { attributes } = props;
		if (
			props.name !== "core/gallery" ||
			(props.name === "core/gallery" &&
				attributes?.ghubVariation !== "justified-gallery")
		) {
			return <BlockListBlock {...props} />;
		}

		const wrapperProps = {
			...props.wrapperProps,
			style: {
				"--ghub--justified--gallery-image-height": attributes?.ghubHeight,
				"--ghub--gallery-column-gap": getGapCSSValue(
					attributes?.ghubHorizontalGap
				),
				"--ghub--gallery-row-gap": getGapCSSValue(attributes?.ghubVerticalGap),
			},
		};

		return <BlockListBlock {...props} wrapperProps={wrapperProps} />;
	};
}, "withBlockWrapper");

addFilter(
	"editor.BlockEdit",
	"ghub-justified-ext/with-advance-controls",
	withAdvanceControls
);
addFilter(
	"blocks.registerBlockType",
	"ghub-justified-ext/add-attributes",
	addAttributes
);
addFilter(
	"editor.BlockListBlock",
	"ghub-justified-ext/with-block-wrapper",
	withBlockWrapper
);
